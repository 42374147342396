var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseLoading', {
    attrs: {
      "value": _vm.loading,
      "skeletonType": ("list-item-avatar-two-line@" + (_vm.pageSize))
    }
  }, [_vm.totalMessages ? _c('div', [_c('v-list', {
    attrs: {
      "two-line": "",
      "data-cy": "message-list"
    }
  }, _vm._l(_vm.messageList, function (message) {
    return _c('v-list-item', {
      key: message.id,
      attrs: {
        "to": ("/message/" + (message.id)),
        "data-cy": "message"
      }
    }, [_c('v-icon', {
      attrs: {
        "left": ""
      }
    }, [_vm._v(_vm._s(message.isRead ? "mdi-email-open" : "mdi-email"))]), _c('v-list-item-content', [_c('v-list-item-subtitle', {
      attrs: {
        "data-cy": "message-sender"
      },
      domProps: {
        "textContent": _vm._s(message.sentFromKursAdmin ? ("Fra: " + (message.sender)) : ("Til: " + (message.recipients[0])))
      }
    }), _c('v-list-item-title', {
      class: {
        'font-weight-bold': !message.isRead
      },
      attrs: {
        "data-cy": "message-subject"
      }
    }, [_vm._v(" " + _vm._s(message.subject) + " "), message.recipients && message.recipients.length > 1 ? _c('v-chip', {
      staticClass: "ml-2",
      attrs: {
        "x-small": "",
        "outlined": "",
        "color": "primary"
      },
      domProps: {
        "textContent": _vm._s('Fellesmelding')
      }
    }) : _vm._e()], 1), _c('v-list-item-subtitle', {
      attrs: {
        "data-cy": "message-text"
      },
      domProps: {
        "textContent": _vm._s(message.text)
      }
    })], 1), _c('v-list-item-action', [_c('v-list-item-action-text', {
      domProps: {
        "textContent": _vm._s(_vm.formatDateTimeRelative(new Date(message.sent)))
      }
    }), _c('v-icon', {
      staticClass: "pt-2"
    }, [_vm._v(" " + _vm._s(_vm.hasAttatchment(message) ? "mdi-paperclip" : undefined) + " ")])], 1)], 1);
  }), 1), _c('v-pagination', {
    staticClass: "ma-5",
    attrs: {
      "length": Math.ceil(_vm.totalMessages / _vm.pageSize)
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1) : _c('v-alert', {
    staticClass: "ma-4",
    attrs: {
      "text": "",
      "type": "info",
      "icon": "mdi-email-open-outline"
    }
  }, [_vm._v("Ingen meldinger")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }