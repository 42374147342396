
import { computed, defineComponent, ref } from "@vue/composition-api";
import BaseLayout from "@/components/shared/layout/BaseLayout.vue";
import BaseModal from "@/components/shared/modal/BaseModal.vue";
import MessageList from "@/components/messages/MessageList.vue";
import SendMessageModal from "@/components/messages/SendMessageModal.vue";
import { useRouter } from "@/shared/useHelpers";
import { ApiGetMessageResultDto } from "@/api/generated/Api";
import { api } from "@/api/api";
import { withLoadingRef } from "@/shared/helpers/otherHelpers";
import { isMobile } from "@/shared/helpers/displayHelpers";

// TODO Possible to get from API?
interface MessageQuery {
  Sender?: boolean;
  IsRead?: boolean;
  SentFromDate?: string;
  SentToDate?: string;
  CourseId?: number;
  PageNumber?: number;
  PageSize?: number;
}

interface MessageProps {
  totalMessages: number;
  messageList: ApiGetMessageResultDto[];
  loading: boolean;
}

const messagePropsInitialState = () => ({
  totalMessages: 0,
  messageList: [],
  loading: false,
});

const PageSize = 10;

export default defineComponent({
  name: "Messages",
  components: {
    BaseLayout,
    MessageList,
    BaseModal,
    SendMessageModal,
  },
  props: {
    routeTab: String,
  },
  setup(props) {
    const sendMessageModalVisible = ref(false);

    const router = useRouter();
    const currentTab = computed({
      set: (tab: string) => {
        router.replace({ params: { tab } });
      },
      get: () => props.routeTab || "unread",
    });

    const getMessageLoader = (query: MessageQuery) => {
      const messageRef = ref<MessageProps>(messagePropsInitialState());
      return {
        messages: messageRef,
        loadPage: async (page: number) =>
          withLoadingRef(messageRef, async () => {
            const messageResult = (
              await api.messaging.searchMessagesMinside({
                ...query,
                PageNumber: page,
                PageSize,
              })
            ).data;
            messageRef.value.messageList = messageResult.items || [];
            messageRef.value.totalMessages = messageResult.totalCount;
          })(),
      };
    };

    const unreadMessages = getMessageLoader({ Sender: false, IsRead: false });
    const inboxMessages = getMessageLoader({ Sender: false });
    const sentMessages = getMessageLoader({ Sender: true });

    const closeSendMessageModal = () => {
      sendMessageModalVisible.value = false;
      sentMessages.loadPage(1);
    };

    return {
      currentTab,
      sendMessageModalVisible,
      closeSendMessageModal,
      unreadMessages,
      inboxMessages,
      sentMessages,
      PageSize,
      isMobile,
    };
  },
});
