var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.sendMessageModalVisible ? _c('BaseModal', [_c('SendMessageModal', {
    on: {
      "close": _vm.closeSendMessageModal
    }
  })], 1) : _vm._e(), _c('BaseLayout', {
    attrs: {
      "display-tabs": ""
    },
    scopedSlots: _vm._u([!_vm.isMobile ? {
      key: "title",
      fn: function () {
        return [_vm._v("Meldinger")];
      },
      proxy: true
    } : null, {
      key: "actions",
      fn: function () {
        return [_c('v-btn', {
          staticClass: "text--primary",
          attrs: {
            "color": "white",
            "data-cy": "send-message"
          },
          on: {
            "click": function ($event) {
              _vm.sendMessageModalVisible = true;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-email-edit-outline")]), _vm._v(" Skriv ny melding ")], 1)];
      },
      proxy: true
    }, {
      key: "tabs",
      fn: function () {
        return [_c('v-tabs', {
          model: {
            value: _vm.currentTab,
            callback: function ($$v) {
              _vm.currentTab = $$v;
            },
            expression: "currentTab"
          }
        }, [_c('v-tab', {
          attrs: {
            "href": "#unread"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-email")]), _c('v-badge', {
          attrs: {
            "value": _vm.unreadMessages.messages.value.totalMessages,
            "content": _vm.unreadMessages.messages.value.totalMessages,
            "color": "error"
          }
        }, [_vm._v(" Ulest ")])], 1), _c('v-tab', {
          attrs: {
            "href": "#inbox"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-email-open-multiple-outline")]), _vm._v(" Innboks ")], 1), _c('v-tab', {
          attrs: {
            "href": "#sent"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-email-send-outline")]), _vm._v(" Sendt ")], 1)], 1)];
      },
      proxy: true
    }], null, true)
  }, [[_c('v-tabs-items', {
    attrs: {
      "value": _vm.currentTab
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "unread"
    }
  }, [_c('MessageList', {
    attrs: {
      "messageList": _vm.unreadMessages.messages.value.messageList,
      "totalMessages": _vm.unreadMessages.messages.value.totalMessages,
      "loading": _vm.unreadMessages.messages.value.loading,
      "pageSize": _vm.PageSize
    },
    on: {
      "pageChanged": _vm.unreadMessages.loadPage
    }
  })], 1), _c('v-tab-item', {
    attrs: {
      "value": "inbox"
    }
  }, [_c('MessageList', {
    attrs: {
      "messageList": _vm.inboxMessages.messages.value.messageList,
      "totalMessages": _vm.inboxMessages.messages.value.totalMessages,
      "loading": _vm.inboxMessages.messages.value.loading,
      "pageSize": _vm.PageSize
    },
    on: {
      "pageChanged": _vm.inboxMessages.loadPage
    }
  })], 1), _c('v-tab-item', {
    attrs: {
      "value": "sent"
    }
  }, [_c('MessageList', {
    attrs: {
      "messageList": _vm.sentMessages.messages.value.messageList,
      "totalMessages": _vm.sentMessages.messages.value.totalMessages,
      "loading": _vm.sentMessages.messages.value.loading,
      "pageSize": _vm.PageSize
    },
    on: {
      "pageChanged": _vm.sentMessages.loadPage
    }
  })], 1)], 1)]], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }