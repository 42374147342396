
import { ApiGetMessageResultDto } from "@/api/generated/Api";
import BaseBreadCrumbs from "@/components/shared/breadcrumbs/BaseBreadCrumbs.vue";
import BaseLoading from "@/components/shared/loading/BaseLoading.vue";
import { formatDateTimeRelative } from "@/shared/helpers/dateHelpers";
import { defineComponent, ref, watch } from "@vue/composition-api";

interface ComponentProps {
  messageList: ApiGetMessageResultDto[];
  totalMessages: number;
  loading: boolean;
  pageSize: number;
}

export default defineComponent({
  name: "MessageList",
  components: {
    BaseBreadCrumbs,
    BaseLoading,
  },
  props: {
    messageList: Array,
    totalMessages: Number,
    loading: Boolean,
    pageSize: Number,
  },
  setup(props: ComponentProps, context) {
    const page = ref(1);

    watch(page, (newPage) => context.emit("pageChanged", newPage), { immediate: true });

    const hasAttatchment = (message: ApiGetMessageResultDto) => {
      if (!message.attachments) {
        return false;
      }
      return message.attachments.length > 0;
    };

    return {
      page,
      formatDateTimeRelative,
      hasAttatchment,
    };
  },
});
